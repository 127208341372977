<template lang="pug">
  .quick-start-page
    .header 
      .nio-jumbo-2.text-white Buyer Studio
      .nio-p-large.text-primary-light Get data streams instantly from Narrative providers.
      NioButton(
        normal-primary 
        @click="newSubscription"
      ) New Subscription
      .graphic
        img(src="https://cdn.narrative.io/data-studio/images/buyer-studio-hero-vector.svg")
    .stats
      .stat(
        v-for="stat of stats"
      )
        .name.nio-h7.text-white {{ stat.name }}
        .value.nio-h2.text-white {{ formatNumber(stat.value) }}
        .annotation.nio-p-small.text-white {{ stat.annotation }} 
    .schema-presets
      .heading.nio-h1.text-primary-darker Schema Presets fit for every use case
      .description.nio-p-large.text-primary-dark Or create a reusable custom Schema Preset with exactly the attributes that you need
      .schema-presets-loading(v-if="loading")
        v-progress-circular.progress(
          size="80" 
          color="#1438F5"
          indeterminate 
        )
      NioTabs(
        v-else
        v-model="activeTab"
        :tabs="tabs"
      )
        template(
          v-slot:narrativeSchemaPresets
        )
          .schema-presets-list(v-if="narrativeSchemaPresets && attributes")
            SchemaPresetList(
              :attributes="attributes"
              :schema-presets="narrativeSchemaPresets"
            )
        template(
          v-slot:customSchemaPresets
        )
          .no-schema-presets(
            v-if="customSchemaPresets && customSchemaPresets.length === 0"
          )
            NioIconFramer(
              icon-name="display-list"
            )
            h3.nio-h3.text-primary-darker You haven't created any schema presets
            .actions
              NioButton.new-schema-preset(
                :key="0"
                normal-primary @click="newSchemaPreset"
              ) Create a New Schema Preset
              NioButton(
                :key="1"
                normal-secondary
                @click="seeHow"
              ) See how schema presets work
          .schema-presets-list(v-else-if="customSchemaPresets && attributes")
            SchemaPresetList(
              :attributes="attributes"
              :schema-presets="customSchemaPresets"
            )
    .steps-section
      .heading.nio-jumbo-2.text-primary-darker Get data streams instantly. 
      .description.nio-p-large.text-primary-dark No more phone calls. No more emails.
      .steps
        .step(
          v-for="step of steps"
        )
          .image(
            :style="{backgroundColor: step.backgroundColor}"
          )
            img(
              :src="step.image"
            )
          .heading.nio-h4.text-primary-darker {{ step.heading }}
          .description.nio-p.text-primary-dark {{ step.description }}
</template>

<script>

import { formatNumberVerbose } from '@/modules/helpers'
import SchemaPresetList from '@/shared/components/SchemaPresetList'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { replacePropertyRefs } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import { getSchemaPresets, getAttributes } from '@/shared/fetchOperations'

export default {
  components: { SchemaPresetList },
  data: () => ({
    loading: true,
    narrativeSchemaPresets: null,
    customSchemaPresets: null,
    attributes: null,
    stats: [
      {
        name: 'Source',
        value: 44,
        annotation: "Providers"
      },
      {
        name: 'Signals',
        value: 2860000,
        annotation: "Events"
      },
      {
        name: 'Mobile Identifiers',
        value: 386000000,
        annotation: "Mobile IDs"
      },
      {
        name: 'PC Identifiers',
        value: 434000000,
        annotation: "Cookie IDs"
      },
      {
        name: 'Identifiers',
        value: 621000000,
        annotation: "Hashed Emails"
      }
    ],
    steps: [
      {
        backgroundColor: '#4CAA76',
        image: 'https://cdn.narrative.io/data-studio/images/quick-start-step-build.svg',
        heading: 'Pick Attributes',
        description: "Or choose a Schema Preset that fits your use case."
      },
      {
        backgroundColor: '#E04036',
        image: 'https://cdn.narrative.io/data-studio/images/quick-start-step-destination.svg',
        heading: 'Set destination',
        description: "Send your data to one or more locations."
      },
      {
        backgroundColor: '#E8A6BC',
        image: 'https://cdn.narrative.io/data-studio/images/quick-start-step-budget.svg',
        heading: 'Set budget',
        description: "Set limits to your monthly spending or go all in. "
      },
      {
        backgroundColor: '#2F58AC',
        image: 'https://cdn.narrative.io/data-studio/images/quick-start-step-activate.svg',
        heading: 'Activate subscription',
        description: "Change your subscription settings anytime."
      }
    ],
    tabs: [
      {
        name: 'narrativeSchemaPresets',
        label: 'Narrative Schema Presets'
      },
      {
        name: 'customSchemaPresets',
        label: 'Custom Schema Presets'
      }
    ],
    activeTab: 'narrativeSchemaPresets'
  }),	
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    async openApiInit() {
      const [attributes, schemaPresets] = await Promise.all([getAttributes(this.$nioOpenApi), getSchemaPresets(this.$nioOpenApi)])
      const rawAttributes = [...attributes]
      this.attributes = attributes.map(attribute => {
        return {
          ...replacePropertyRefs(attribute, rawAttributes)
        }
      })
      this.loading = false
      this.narrativeSchemaPresets = schemaPresets.filter(schemaPreset => schemaPreset.scope === 'public')
      this.customSchemaPresets = schemaPresets.filter(schemaPreset => schemaPreset.scope === 'private')      
    },
    newSubscription() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'new'
      },"*")
    },
    goToDataPackage(dataPackage) {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      parent.postMessage({
        name: 'pageNavigation',
        payload: `new/${dataPackage.slug}`
      },"*")
    },
    formatNumber(val) {
      return formatNumberVerbose(val)
    },
    newSchemaPreset() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'custom-schema-presets'
      },"*")
    },
    seeHow()  {
      window.open('https://kb.narrative.io/buyer-studio-presets', '_blank')
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.quick-start-page
  display: flex
  flex-direction: column
  align-items: stretch
  .header
    padding: 4rem
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    background-color: #06164f
    .graphic
      width: 40.625rem
      height: 25rem
      margin-bottom: -1.5rem
      margin-top: -1.5rem
      img
        width: 100%
        height: 100%
    .nio-p-large
      margin-top: 0.5rem
    .nio-button
      margin-top: 1.5rem
      margin-bottom: 3rem
  .stats
    display: flex
    justify-content: space-around
    align-items: center
    padding: 3rem 0rem
    background-color: #3058ad
    & > .stat + .stat
      border-left: 0.0625rem solid $c-primary-lighter
    .stat
      width: 20%
      display: flex
      flex-direction: column
      align-items: center
      .name
      .value
      .annotation    
  .schema-presets
    min-height: 21.25rem
    padding: 4.625rem 1.5rem
    .schema-presets-loading
      width: 100%
      height: 100%
      position: relative
      .v-progress-circular
        position: absolute
        left: 50%
        top: 10.625rem
        margin-top: -8.5rem
        margin-left: -2.5rem
        z-index: 2
    .heading, .description
      text-align: center
    .description
      margin: 0.5rem auto 3rem auto
    .nio-tabs
      margin-top: 1.5rem	
    .no-schema-presets
      padding: 9.6875rem 1.5rem 11.1875rem 1.5rem
      background-color: $c-canvas
      border: 0.0625rem solid $c-primary-lighter
      border-radius: 0.75rem
      display: flex
      flex-direction: column
      align-items: center
      .nio-icon-framer
        margin-bottom: 1rem
      h3
        margin-bottom: 1.5rem
      p    
        margin-bottom: 2.5rem
      .actions
        display: flex
        justify-content: center
        align-items: center
        & > * + *
          margin-left: 1rem
  .steps-section
    padding: 3rem 1.5rem
    & > .heading, & > .description
      text-align: center
    & > .description
      margin-top: 0.5rem
    .steps
      margin-top: 3.5rem
      padding: 0rem 1.25rem
      display: flex
      justify-content: space-around
      .step
        width: 12.5rem
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        flex-basis: 0
        text-align: center
        .image
          width: 10rem
          height: 10rem
          border-radius: 50%
          display: flex
          justify-content: center
          align-items: center
          .graphic
            width: 2.5rem
            height: 2.5rem
        .heading
          margin-top: 1rem
        .description
          margin-top: 1rem
</style>